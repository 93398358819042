const icons = [
  'activity',
  'alert-circle',
  'alert-triangle',
  'archive',
  'arrow-back',
  'arrow-circle-down',
  'arrow-circle-left',
  'arrow-circle-right',
  'arrow-circle-up',
  'arrow-down',
  'arrow-downward',
  'arrow-forward',
  'arrow-ios-back',
  'arrow-ios-downward',
  'arrow-ios-forward',
  'arrow-ios-upward',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'arrow-upward',
  'arrowhead-down',
  'arrowhead-left',
  'arrowhead-right',
  'arrowhead-up',
  'at',
  'attach-2',
  'attach',
  'award',
  'backspace',
  'bar-chart-2',
  'bar-chart',
  'battery',
  'behance',
  'bell-off',
  'bell',
  'bluetooth',
  'book-open',
  'book',
  'bookmark',
  'briefcase',
  'browser',
  'brush',
  'bulb',
  'calendar',
  'camera',
  'car',
  'cast',
  'charging',
  'checkmark-circle-2',
  'checkmark-circle',
  'checkmark',
  'checkmark-square-2',
  'checkmark-square',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'clipboard',
  'clock',
  'close-circle',
  'close',
  'close-square',
  'cloud-download',
  'cloud-upload',
  'code-download',
  'code',
  'collapse',
  'color-palette',
  'color-picker',
  'compass',
  'copy',
  'corner-down-left',
  'corner-down-right',
  'corner-left-down',
  'corner-left-up',
  'corner-right-down',
  'corner-right-up',
  'corner-up-left',
  'corner-up-right',
  'credit-card',
  'crop',
  'cube',
  'diagonal-arrow-left-down',
  'diagonal-arrow-left-up',
  'diagonal-arrow-right-down',
  'diagonal-arrow-right-up',
  'done-all',
  'download',
  'droplet-off',
  'droplet',
  'edit-2',
  'edit',
  'email',
  'expand',
  'external-link',
  'eye-off-2',
  'eye-off',
  'eye',
  'facebook',
  'file-add',
  'file',
  'file-remove',
  'file-text',
  'film',
  'flag',
  'flash-off',
  'flash',
  'flip-2',
  'flip',
  'folder-add',
  'folder',
  'folder-remove',
  'funnel',
  'gift',
  'github',
  'globe-2',
  'globe',
  'google',
  'grid',
  'hard-drive',
  'hash',
  'headphones',
  'heart',
  'home',
  'image',
  'inbox',
  'info',
  'keypad',
  'layers',
  'layout',
  'link-2',
  'link',
  'linkedin',
  'list',
  'loader',
  'lock',
  'log-in',
  'log-out',
  'map',
  'maximize',
  'menu-2',
  'menu-arrow',
  'menu',
  'message-circle',
  'message-square',
  'mic-off',
  'mic',
  'minimize',
  'minus-circle',
  'minus',
  'minus-square',
  'monitor',
  'moon',
  'more-horizontal',
  'more-vertical',
  'move',
  'music',
  'navigation-2',
  'navigation',
  'npm',
  'options-2',
  'options',
  'pantone',
  'paper-plane',
  'pause-circle',
  'people',
  'percent',
  'person-add',
  'person-delete',
  'person-done',
  'person',
  'person-remove',
  'phone-call',
  'phone-missed',
  'phone-off',
  'phone',
  'pie-chart',
  'pin',
  'play-circle',
  'plus-circle',
  'plus',
  'plus-square',
  'power',
  'pricetags',
  'printer',
  'question-mark-circle',
  'question-mark',
  'radio-button-off',
  'radio-button-on',
  'radio',
  'recording',
  'refresh',
  'repeat',
  'rewind-left',
  'rewind-right',
  'save',
  'scissors',
  'search',
  'settings-2',
  'settings',
  'shake',
  'share',
  'shield-off',
  'shield',
  'shopping-bag',
  'shopping-cart',
  'shuffle-2',
  'shuffle',
  'skip-back',
  'skip-forward',
  'slash',
  'smartphone',
  'smiling-face',
  'speaker',
  'square',
  'star',
  'stop-circle',
  'sun',
  'swap',
  'sync',
  'text',
  'thermometer-minus',
  'thermometer',
  'thermometer-plus',
  'toggle-left',
  'toggle-right',
  'trash-2',
  'trash',
  'trending-down',
  'trending-up',
  'tv',
  'twitter',
  'umbrella',
  'undo',
  'unlock',
  'upload',
  'video-off',
  'video',
  'volume-down',
  'volume-mute',
  'volume-off',
  'volume-up',
  'wifi-off',
  'wifi',
] as const;
type BaseIconType = typeof icons[number];
export type IconType = BaseIconType | `${BaseIconType}-outline`;

export const IconName: Record<IconType, IconType> = icons
  .map((i) => [i, `${i}-outline`] as IconType[])
  .flat()
  .reduce((prev, curr) => ({ ...prev, [curr]: curr }), {}) as Record<
  IconType,
  IconType
>;

const nicer = <T extends { [k: string]: BaseIconType }>(a: T): T => a;

export const Icons = nicer({
  activity: 'activity',
  alert: 'alert-circle',
  arrowDownCircle: 'arrow-circle-down',
  brush: 'brush',
  moreH: 'more-horizontal',
  moreV: 'more-vertical',
  people: 'people',
  plusCircle: 'plus-circle',
  plus: 'plus',
  share: 'share',
  google: 'google',
  twitter: 'twitter',
  radio: 'radio',
  lock: 'lock',
  award: 'award',
  play: 'arrow-right',
  info: 'info',
  copy: 'copy',
  filter: 'funnel',
  chat: 'message-circle',
  externalLink: 'external-link',
  person: 'person',
  folder: 'folder',
  grid: 'grid',
  checkmarkCircle: 'checkmark-circle',
  eye: 'eye',
  eyeOff: 'eye-off',
  square: 'square',
  checkbox: 'checkmark-square',
  camera: 'camera',
  text: 'text',
  undo: 'undo',
  trendingUp: 'trending-up',
  trash: 'trash',
  shield: 'shield',
  email: 'email',
  refresh: 'refresh',
  chevronUp: 'chevron-up',
  chevronDown: 'chevron-down',
  chevronLeft: 'chevron-left',
  chevronRight: 'chevron-right',
  personAdd: 'person-add',
  logOut: 'log-out',
  bulb: 'bulb',
  heart: 'heart',
  close: 'close',
  alertCircle: 'alert-circle',
  home: 'home',
  menu: 'menu',
  questionMarkCircle: 'question-mark-circle',
  compass: 'compass',
  playCircle: 'play-circle',
  barChart: 'bar-chart',
  settings: 'settings',
  book: 'book',
  messageCircle: 'message-circle',
  search: 'search',
  arrowUpCircle: 'arrow-circle-up',
  arrowBack: 'arrow-back',
  arrowUp: 'arrow-up',
  arrowDown: 'arrow-down',
  bookmark: 'bookmark',
  bell: 'bell',
  bellOff: 'bell-off',
  slash: 'slash',
  clock: 'clock',
  verified: 'checkmark-circle-2',
});

export const IconsOutlined = Object.keys(Icons)
  .map((k) => k as keyof typeof Icons)
  .reduce(
    (prev, curr) => ({ ...prev, [curr]: `${Icons[curr]}-outline` }),
    {}
  ) as Record<keyof typeof Icons, IconType>;
